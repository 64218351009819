import React from 'react';
import img  from "./cross.png"
const SorryMessage = ({ isOpenMessage, closemodel }) => {
  const handleClose = (e) => {

    // Close the modal if clicking outside the modal content
    if (e.target === e.currentTarget) {
      closemodel();
    }
  };

  return (
    <div
      className={isOpenMessage ? "fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center" : "hidden"}
      onClick={handleClose}
    >
      <div className="bg-white p-8  text-center rounded-lg shadow-lg max-w-sm w-full">
        <div className=' flex justify-center items-center'>

        <img src={img} className=' w-24 h-24'/>
        </div>
        <h1 className="text-4xl font-bold text-[#C11C84] mb-4 mt-2">Sorry!</h1>
        <p className="text-lg text-gray-700">
          Betting is Closed for today.
        </p>
        <p className="text-lg text-gray-700">
          Please come next day to play
        </p>
        <button
          className="mt-6 px-4 py-2 bg-[#770E73] w-full text-white rounded hover:bg-red-700"
          onClick={closemodel}
        >
          OK
        </button>
      </div>

    </div>
  );
};

export default SorryMessage;
