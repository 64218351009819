import React, { useEffect, useState } from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { starlineAction } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Loader from '../../components/Loader/Loader';

const StarlineChart = () => {
    const selector = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(starlineAction.calanderForStarLine());
    }, []);

    const goBack = () => {
        window.history.back();
    };


    let {starline} = selector?selector:{};
    let {getStarlineChartList,loading} = starline?starline:{};
    
    return (
        <>
        <Loader Loading={loading}/>
            <div className='bg-white text-black h-screen'>
                <div className='h-10 w-full border bg-[#770E73] rounded-b-3xl flex justify-start items-end px-4 gap-3'>
                    <IoArrowBackOutline
                        className='text-white mb-1 text-lg cursor-pointer'
                        onClick={goBack}
                    />
                    <div className='font-semibold text-white cursor-pointer uppercase text-sm md:text-base'>
                        Starline Chart
                    </div>
                </div>


                <div className='p-4 h-[99vh] overflow-auto'>
                    {getStarlineChartList && getStarlineChartList.length > 0 && getStarlineChartList?.map((item, index) => (
                        <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs">
                            <div className="mb-2">
                                <strong>Date: </strong>
                                {moment(item.date).format('DD-MMM-YYYY')}

                            </div>
                            <div className="grid grid-cols-1">
                                {item.results.map((result, idx) => (
                                    <div key={idx} className="p-2 border flex justify-between rounded-md shadow-sm my-1">

                                        <div>
                                            <div>
                                                {moment(result.gameTime, 'HH:mm').format('hh:mm A')}
                                            </div>

                                        </div>
                                        <div>

                                            {result.jodiNumber}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default StarlineChart;
