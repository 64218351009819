import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { TfiReload } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { userActions } from "../../_actions";
import { BsWallet } from "react-icons/bs";
import { IoMenuOutline } from "react-icons/io5";
import logo from "./logopw.png";
const Header = (props) => {
  let { opensizebar, setopensizebar } = props;
  const [navbarOpen, setNavbarOpen] = useState(false);
  const dispatch = useDispatch();

  const onClickMenutwo = (url) => {
    localStorage.removeItem("user");
    props.history.push(url);
  };

  const navigate = (url) => {
    props.history.push(url);
    setOpenModal(!openModal);
  };

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  useEffect(() => {
    dispatch(userActions.getProfile());

    // Handle click outside
    const handleClickOutside = (event) => {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setClickedOutside(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const [openModal, setOpenModal] = useState(false);
  let user = JSON.parse(localStorage.getItem("spuser"));

  const { users } = props;
  const { getProfile } = users;
  let handleRefresh = () => {
    dispatch(userActions.getProfile());
  };

  return (
    <div>
      <div className="bg-[#770E73] p-1 w-full h-[20vh]">
        <div className="flex items-center justify-between  md:sticky top-0 z-40 h-[50px] lg:h-[76px] px-5 relative w-full">
          <div className="flex  justify-start   items-center lg:pl-10">
            {/* <img
              onClick={() => navigate("/app/dashboard")}
              src={logo}
              alt="logo"
              className="lg:h-[80px] h-[50px] ml-4 md:ml-1 cursor-pointer"
            /> */}

            <span className=" px-3 font-bold  text-base whitespace-nowrap text-white">
             247 skyexch
            </span>
            <IoMenuOutline
              onClick={() => setopensizebar((prev) => !prev)}
              className=" text-white absolute cursor-pointer left-1 md:left-4 text-2xl font-bold"
            />
          </div>
          <div className="flex space-x-1 items-center">
            <div className="text-white md:relative">
              <div className="flex items-center lg:space-x-20 space-x-5">
                <div className="flex  gap-2  items-center text-xl lg:text-sm text-white space-x-1 leading-none rounded cursor-pointer">
                  <div className="flex gap-2 text-xl  bg-[#850355]  justify-center items-center rounded-lg px-2 ">
                    <BsWallet className="" />
                    <span className="">
                      {getProfile && getProfile.wallet
                        ? getProfile.wallet
                        : "0"}
                    </span>
                  </div>
                  {/* <TfiReload
                    onClick={handleRefresh}
                    className=" font-bold text-xl "
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Header);
