import React from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { MdNotificationsActive } from "react-icons/md";


function Notification() {

    const currentDate = new Date().toLocaleDateString(); 
        return (
            <>
                <div className='bg-[#EEEEEE] h-screen  w-full '>
                    <div className='flex justify-between place-items-center h-10 w-full border bg-[#770E73] rounded-b-3xl'>
                        <div className=" flex justify-start place-items-end px-4 gap-3">
                            <IoArrowBackOutline
                                className="text-white mb-1 text-lg"
                                onClick={() => window.history.back()}
                            />
                            <div className="  font-semibold text-white cursor-pointer">
                                {" "}
                                Notification
                            </div>
                        </div>
                    </div>

                    <div className=' space-x-1 p-1 h-[90vh] overflow-hidden overflow-y-auto mt-4'>
                        <div className='px-2'>
                            <div className='  flex justify-between place-items-center text-black mb-10   bg-gray-50 rounded shadow p-3'>
                                <div className='flex justify-start place-items-center  '>
                                    <div className='flex justify-center place-items-center bg-[#770E73] px-1 py-1 rounded-full'>
                                        <MdNotificationsActive className='text-white text-lg' />
                                    </div>
                                    <div className='py-1 rounded-md mx-4'>
                                        <p>
                                            Holiday
                                        </p>
                                        <p className='text-[12px] '>सभी भाइयो को सूचित किया जाता है</p>
                                    </div>
                                </div>
                                <p className='flex justify-end  place-items-end text-[11px]'>{currentDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    export default Notification
