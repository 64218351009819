import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoCaretBackCircleSharp } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';
const Chart = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData, gameList, loading } = users;

  useEffect(() => {
    let data =
    {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 100
    }
    // dispatch(userActions.getAllAppSetting());
    dispatch(userActions.getGamesList(data));
  }, [])




  return (
    <>
      <Loader loading={loading} />

      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex justify-between place-items-center h-10 w-full border bg-[#770E73] rounded-b-3xl'>
          <div className=" flex justify-start place-items-end px-4 gap-3">
            <IoArrowBackOutline
              className="text-white mb-1 text-lg"
              onClick={() => window.history.back()}
            />
            <div className="  font-semibold text-white cursor-pointer">
              {" "}
              Game Chart List
            </div>
          </div>
        </div>

        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>

          <div className='w-full'>
           

            <div className=' p-5 flex flex-col gap-4 rounded-md mb-10 '>
              {gameList && gameList.length > 0 && gameList.map((e) => {
                return (
                  <div  title='chart' className='  flex justify-between place-items-center  capitalize cursor-pointer bg-white shadow-md items-center px-3 py-4 rounded-lg font-bold text-white'>
                    <div className='text-black'>{e?.name}</div>
                    <div className='flex justify-start place-items-center gap-2'>
                      <button onClick={() => history.push(`/app/chartjodi/${e?._id}/${e?.name}`)}  className='bg-amber-900 px-2 py-q rounded-md'>Jodi</button>
                      <button onClick={() => history.push(`/app/charts/${e?._id}/${e?.name}`)} className='   bg-amber-900 px-2 py-q rounded-md'>Panel</button>

                    </div>
                  </div>
                )

              })}

            </div>




          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(Chart));