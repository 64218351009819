import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "react-select-search";
import { starlineAction } from "../../_actions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";


function IndianStarlineBidHiatory() {
    const selector = useSelector((state) => state);
    // console.log("sele_____", selector);

    const dispatch = useDispatch();
    const [offset, setoffset] = useState(0);
    const [size, setsize] = useState(25);
    const [page, setpage] = useState(1);
    const [index, setindex] = useState(0);
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");

    useEffect(() => {
        let data = {
            gameId: "",
            fromDate: fromDate,
            toDate: toDate,
            keyWord: "",
            sortBy: "",
            sortOrder: "",
            pageNo: page,
            size: size,
        };
        dispatch(starlineAction.getBetsListStarLine(data));
    }, [page, toDate]);

    const handleChange = async (e) => {
        if (page !== 1) {
            setpage(1);
        }
        setfromDate(e.target.value);
        settoDate(e.target.value);
    };

    let handlePageClick = (data) => {
        console.log("data  ", data);
        setindex(data.selected);
        let offset = Math.ceil(data.selected * size);
        setoffset(offset);
        setpage(data.selected + 1);
    };
    const goBack = () => {
        window.history.back();
    };
    let { starline } = selector ? selector : {};
    let { getStarlineBetList, getStarlineBetTotal } = starline ? starline : {};

    return (
        <>


            <Loader Loader={selector?.starline?.loading} />
            <div className="bg-white text-black min-h-screen">
                <div className="h-10 w-full border bg-[#770E73] rounded-b-3xl flex justify-start place-items-end px-4 gap-3">
                    <IoArrowBackOutline
                        className="text-white mb-1 text-lg cursor-pointer"
                        onClick={goBack}
                    />
                    <div className="  font-semibold text-white cursor-pointer uppercase">
                        {" "}
                        indian Starline bid History
                    </div>
                </div>
                <div className="flex justify-between items-center p-3 text-xs md:text-sm">
                    <p>Select Date</p>
                    <div className="mt-2 md:mt-0">
                        <input
                            type="date"
                            onChange={(e) => handleChange(e)}
                            className="bg-white px-3 py-1 uppercase rounded-full shadow w-full md:w-auto"
                        />
                    </div>
                </div>
                <div className="p-4 overflow-hidden overflow-y-auto h-[90vh]">
                    <div className="">
                        <div className="flex flex-col gap-5  h-full ">
                            {getStarlineBetList &&
                                getStarlineBetList.length > 0 &&
                                getStarlineBetList.map((element, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex flex-col bg-white text-xs  border-gray-500 rounded-md border p-4 "
                                        >
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>S.No.</div> {offset + index + 1}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>Market</div> {element?.marketId?.name || "-"}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>Game</div> {element?.gameId?.name || "-"}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>NUMBER</div> {element?.betNumber || "-"}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>Point</div> {element?.amount || "-"}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>BID TYPE</div> {element?.isOpen ? "Open" : "Close"}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>Date</div>
                                                {moment(new Date(parseInt(element.createdAt)))
                                                    .utcOffset("+05:30")
                                                    .format("DD-MM-YYYY hh:mm A")}
                                            </div>
                                            <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                                                <div>Profit/Loss</div>
                                                {element?.betStatus === 0 && (
                                                    <div className="text-yellow-500">Pending</div>
                                                )}
                                                {element?.betStatus === 1 && (
                                                    <div className="text-green-800">
                                                        +{element?.winAmount}
                                                    </div>
                                                )}
                                                {element?.betStatus === 2 && (
                                                    <div className="text-red-700">
                                                        -{element?.lossAmount}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}

                            <nav className="flex justify-end mt-4 w-full">
                                {getStarlineBetTotal && getStarlineBetTotal > size ? (
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={getStarlineBetTotal / size}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-cls"}
                                        activeClassName={"active"}
                                    />
                                ) : null}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndianStarlineBidHiatory;
