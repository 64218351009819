import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { creditWithdraw } from './creditWithdraw.reducer';
import { createapp } from './createapp.reducer';
import { bank } from './bank.reducer';
import { bet } from './bet.reducer';
import { createAccount } from './createAccount.reducer';
import { gameOfResult } from './gameResult.reducer'
import { jackpot } from './jackpot.reducer';
import { starline } from './starline.reducer';
import { jackpotchart } from './jackpotchart.reducer';
import { updateBankDetails } from './updateBankDetails.reducer';
import { Payment } from './payment.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  creditWithdraw,
  createapp,
  bank,
  bet,
  createAccount,
  gameOfResult,
  jackpot, 
  starline,
  jackpotchart,
  updateBankDetails,  
  Payment
});

export default rootReducer;
