import React, { useEffect, useState } from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { jackportchartAction } from '../../_actions';
import moment from 'moment';
import Loader from '../../components/Loader/Loader';


const JackpotChart = () => {
    const selector = useSelector(state => state);
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState()
    const [chartDataTotal, setChartDataTotal] = useState()
    const [offset, setoffset] = useState(0)
    const [size, setsize] = useState(2)
    const [page, setpage] = useState(1)
    const [index, setindex] = useState(0)
    const [fromDate, setfromDate] = useState('')
    const [toDate, settoDate] = useState('')

    useEffect(() => {

        dispatch(jackportchartAction.calanderForJackpot())
    }, [])
    useEffect(() => {
        setChartData(() => selector?.jackpotchart?.getjackpotChartList?.calanderForJackpot?.data)
    }, [selector])



    const goBack = () => {
        window.history.back()
    };
    let {jackpotchart} = selector?selector:{};
    let {getjackpotChartList} = jackpotchart?jackpotchart:{};
    return (
        <>
       
            <div className='bg-white h-screen'>
                <div className='h-10 w-full border bg-[#770E73] rounded-b-3xl flex justify-start items-end px-4 gap-3'>
                    <IoArrowBackOutline
                        className='text-white mb-1 text-lg cursor-pointer'
                        onClick={goBack}
                    />
                    <div className='font-semibold text-white cursor-pointer uppercase text-sm md:text-base'>
                        Jackpot Chart
                    </div>
                </div>

                <div className="container mx-auto p-4 h-[99vh] overflow-auto">
                    {getjackpotChartList && getjackpotChartList.length > 0 && getjackpotChartList?.map((item, index) => (
                        <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs">
                            <div className="mb-2">
                                <strong>Date: </strong>
                                {moment(item.date).format('DD-MMM-YYYY')}

                            </div>
                            <div className="grid grid-cols-1">
                                {item.results.map((result, idx) => (
                                    <div key={idx} className="p-2 border flex justify-between rounded-md shadow-sm my-1">

                                        <div>
                                            <div>
                                                {moment(result.gameTime, 'HH:mm').format('hh:mm A')}
                                            </div>

                                        </div>
                                        <div>

                                            {result.jodiNumber}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </>
    );
};

export default JackpotChart;
