import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaMobileAlt, FaUserAlt, FaLock, FaEyeSlash, FaEye } from 'react-icons/fa';
import { userActions } from '../../_actions';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

const Signup = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const history = useHistory();

    const [fieldslogin, setFieldsLogin] = useState({
        userNames: '',
        mobileNo: '',
        password: '',
        confirmPassword: '',
        OTP: ''
    });
    const [errorslogin, setErrorsLogin] = useState({});
    const [otpFeilds, setOtpFeilds] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const inputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFieldsLogin(prevState => ({ ...prevState, [name]: value }));
        setErrorsLogin(prevState => ({ ...prevState, [name]: '' }));
    
    };


    const loginSubmit = () => {
        const { userNames, mobileNo, password, confirmPassword, OTP } = fieldslogin;
        const errors = {};

        // Validate inputs before OTP is sent
        if (!otpFeilds) {
            if (!userNames.trim()) {
                errors.userNames = 'Username is required';
            }

            if (!mobileNo) {
                errors.mobileNo = 'Mobile number is required';
            } else if (!/^\d{10}$/.test(mobileNo)) {
                errors.mobileNo = 'Mobile number must be 10 digits';
            }

            if (!password) {
                errors.password = 'Password is required';
            } else if (password.length < 6) {
                errors.password = 'Password must be at least 6 characters long';
            }

            if (!confirmPassword) {
                errors.confirmPassword = 'Confirm Password is required';
            } else if (password !== confirmPassword) {
                errors.confirmPassword = 'Passwords do not match';
            }

            setErrorsLogin(errors);
        
            let formattedMobileNo = fieldslogin.mobileNo.startsWith('91') ? `+${mobileNo}` : `+91${mobileNo}`;
            if (Object.keys(errors).length === 0) {
                // Send OTP
                let data = {
                    userName: fieldslogin.userNames,
                    mobNo: formattedMobileNo,
                    password: fieldslogin.password,
                    confirmPassword: fieldslogin.confirmPassword
                };
                dispatch(userActions.userRegister(data, setOtpFeilds));

            }
        } else {

            if (!OTP) {
                errors.OTP = 'OTP is required';
            } else if (!/^\d{7}$/.test(OTP)) {
                errors.OTP = 'OTP must be 7 digits';
            }
            setErrorsLogin(errors);
            if (Object.keys(errors).length === 0) {
                let OtpData = {
                    token: selector?.users?.registerUser?.userToken?selector?.users?.registerUser?.userToken:null,
                    otp: fieldslogin.OTP
                };
                dispatch(userActions.registerValidateOtp(OtpData, history));
            }
        }
    };


    return (
        <>
            <Loader loading={selector?.users?.loading} />
            <div className='bg-black/80'>
                <div className='relative'>
                    <div className="min-h-screen flex flex-col justify-center relative z-20">
                        <div className='mx-auto w-full bg-no-repeat rounded-lg max-w-lg'>
                            <div className='mx-auto h-screen flex justify-center items-center flex-col bg-no-repeat py-0 rounded-xl relative overflow-hidden'>
                                <div className="w-full h-[30vh] bg-[#770E73] absolute top-0">
                                    <div className="border-l-4 border-white uppercase text-white m-4 ps-2 font-semibold">
                                        {/* Your content here */}
                                    </div>
                                </div>
                                <div className="h-screen w-full place-items-center bg-white rounded-tl-[100px] absolute top-20">
                                    <div className="mx-10 mt-20">
                                        <img src='/—Pngtree—sign up now_7152614.png' className='w-40 h-40 mx-auto' alt='' />
                                        <div className="w-full flex flex-col relative mb-2">
                                            <input
                                                type="number"
                                                name="mobileNo"
                                                id="mobileNo"
                                                value={fieldslogin?.mobileNo || ""}
                                                className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                                                placeholder="Mobile Number"
                                                onChange={inputChange}
                                                disabled={otpFeilds}
                                            />
                                            {errorslogin.mobileNo && (
                                                <div className="text-red-500 text-left text-xs">
                                                    {errorslogin.mobileNo}
                                                </div>
                                            )}
                                            <span className="absolute top-4 left-4 w-7 h-8 bg-[#770E73] flex justify-center place-items-center rounded-lg">
                                                <FaMobileAlt className="text-white" />
                                            </span>
                                        </div>
                                        <div className="w-full flex flex-col relative mb-2">
                                            <input
                                                type="text"
                                                name="userNames"
                                                id="userNames"
                                                value={fieldslogin?.userNames || ""}
                                                className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                                                placeholder="User Names"
                                                onChange={inputChange}
                                                autoComplete="off"
                                                disabled={otpFeilds}
                                            />
                                            {errorslogin.userNames && (
                                                <div className="text-red-500 text-left text-xs">
                                                    {errorslogin.userNames}
                                                </div>
                                            )}
                                            <span className="absolute top-4 left-4 w-7 h-8 bg-[#770E73] flex justify-center place-items-center rounded-lg">
                                                <FaUserAlt className="text-white" />
                                            </span>
                                        </div>
                                        <div className="w-full flex flex-col relative mb-2">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                id="password"
                                                value={fieldslogin?.password || ""}
                                                className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                                onChange={inputChange}
                                                disabled={otpFeilds}
                                            />
                                            {errorslogin.password && (
                                                <div className="text-red-500 text-left text-xs">
                                                    {errorslogin.password}
                                                </div>
                                            )}
                                            <span className="absolute top-4 left-4 w-7 h-8 bg-[#770E73] flex justify-center place-items-center rounded-lg">
                                                <FaLock className="text-white" />
                                            </span>
                                            <span
                                                className="absolute top-4 right-4 w-7 h-8 flex justify-center place-items-center cursor-pointer"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <FaEyeSlash className="text-gray-600" /> : <FaEye className="text-gray-600" />}
                                            </span>
                                        </div>
                                        <div className="w-full flex flex-col relative mb-2">
                                            <input
                                                type={showConfirmPassword ? "text" : "password"}
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                value={fieldslogin?.confirmPassword || ""}
                                                className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                                                placeholder="Confirm Password"
                                                onChange={inputChange}
                                                disabled={otpFeilds}

                                            />
                                            {errorslogin.confirmPassword && (
                                                <div className="text-red-500 text-left text-xs">
                                                    {errorslogin.confirmPassword}
                                                </div>
                                            )}
                                            <span className="absolute top-4 left-4 w-7 h-8 bg-[#770E73] flex justify-center place-items-center rounded-lg">
                                                <FaLock className="text-white" />
                                            </span>
                                            <span
                                                className="absolute top-4 right-4 w-7 h-8 flex justify-center place-items-center cursor-pointer"
                                                onClick={toggleConfirmPasswordVisibility}
                                            >
                                                {showConfirmPassword ? <FaEyeSlash className="text-gray-600" /> : <FaEye className="text-gray-600" />}
                                            </span>
                                        </div>
                                        {otpFeilds &&
                                            <div className="w-full flex flex-col relative mb-2">
                                                <input
                                                    type="number"
                                                    name="OTP"
                                                    id="OTP"
                                                    value={fieldslogin?.OTP || ""}
                                                    className="px-12 py-5 placeholder-blueGray-400 rounded-lg bg-[#EEEEEE] text-sm shadow-md w-full ease-linear transition-all duration-150"
                                                    placeholder="Enter 7 Digit OTP"
                                                    onChange={inputChange}
                                                />
                                                {errorslogin.confirmPassword && (
                                                    <div className="text-red-500 text-left text-xs">
                                                        {errorslogin.confirmPassword}
                                                    </div>
                                                )}
                                                <span className="absolute top-4 left-4 w-7 h-8 bg-[#770E73] flex justify-center place-items-center rounded-lg">
                                                    <FaLock className="text-white" />
                                                </span>
                                            </div>
                                        }


                                        <div className="w-full flex justify-center items-center border-b pb-6">
                                            <button
                                                type="button"
                                                onClick={loginSubmit}
                                                className="flex justify-center items-center w-[80%] py-2 bg-[#770E73] text-white font-semibold text-lg rounded-full mt-5"
                                            >
                                                <span className="tracking-wider">{!otpFeilds ? 'Send Otp' : 'Register & Login'}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
