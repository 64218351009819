import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { IoArrowBack } from "react-icons/io5";
import { IoCaretBackCircle } from "react-icons/io5";
import Loader from "../../components/Loader/Loader";

class SattaEvents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameOne: "",
      gameTwo: "",
      gameThree: "",
      gameFour: "",
      gameItem: {},
      activeTab: 1,
      editStake: false,
      isOpen: "",

      data: {
        limit: 10,
        pageno: 1,
      },
    };
  }
  componentDidMount() {
    let data = {
      gameId: this.props.match.params.gameId,
    };
    if (this.props.match.params) {
      this.setState({ isOpen: this.props.match.params?.isOpen });
    }
    // this.props.dispatch(userActions.game_profile(data));
    this.props.dispatch(userActions.getMarketListByGameId(data));
  }

  // navigateToGameSingle = (data) => {

  //   console.log(data && data.shortName && data.shortName)
  //   if (data && data.shortName && data.shortName == "SINGLEDIGIT") {

  //     this.props.history.push(`/app/SingleDigit/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "SINGLEBULK") {

  //     this.props.history.push(`/app/SingleDigitBulk/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "ODD_EVEN") {

  //     this.props.history.push(`/app/matkaOddEven/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "JODI") {

  //     this.props.history.push(`/app/matkaJodi/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "SINGLE_PATTI") {

  //     this.props.history.push(`/app/matkaSinglePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "DOUBLE_PATTI") {

  //     this.props.history.push(`/app/matkaDoublePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "TRIPLE_PATTI") {

  //     this.props.history.push(`/app/matkaTriplePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "KolkataSINGLE") {

  //     this.props.history.push(`/app/kolkataSingle/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  //   if (data && data.shortName && data.shortName == "KolkataSinglePanna") {

  //     this.props.history.push(`/app/kolkataSinglePanna/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  //   }
  // }

  navigateToGameSingle = (data, elem) => {
    console.log(data && data.shortName && data.shortName);

    if (data && data.shortName) {
      const { gameName, gameId } = this.props.match.params;
      const { _id } = data;
      const basePath = `/app`;

      switch (data.shortName) {
        case "SINGLEDIGIT":
          this.props.history.push(
            `${basePath}/SingleDigit/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "SINGLEBULK":
          this.props.history.push(
            `${basePath}/SingleDigitBulk/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "SINGLEPANA":
          this.props.history.push(
            `${basePath}/SinglePana/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "SINGLEPANABULK":
          this.props.history.push(
            `${basePath}/SinglePanaBulk/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "DOUBLEPANA":
          this.props.history.push(
            `${basePath}/DoublePana/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "DOUBLEPANABULK":
          this.props.history.push(
            `${basePath}/DoublePanaBulk/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "TRIPLEPANA":
          this.props.history.push(
            `${basePath}/TripplePanna/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "JODI":
          this.props.history.push(
            `${basePath}/Jodi/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "REDJODI":
          this.props.history.push(
            `${basePath}/RedJodi/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "JODIBULK":
          this.props.history.push(
            `${basePath}/JodiBulk/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "ODD_EVEN":
          this.props.history.push(
            `${basePath}/matkaOddEven/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "PANAFAMILY":
          this.props.history.push(
            `${basePath}/Pannafamily/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "JODIFAMILY":
          this.props.history.push(
            `${basePath}/JodiFamily/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "TWODIGITPANA":
          this.props.history.push(
            `${basePath}/TwoDigitPana/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "FULLSANGAM":
          this.props.history.push(
            `${basePath}/FullSangam/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "HALFSANGAM_A":
          this.props.history.push(
            `${basePath}/HalfSangamA/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "HALFSANGAM_B":
          this.props.history.push(
            `${basePath}/HalfSangamB/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;

        case "SINGLE_PATTI":
          this.props.history.push(
            `${basePath}/matkaSinglePatti/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "DOUBLE_PATTI":
          this.props.history.push(
            `${basePath}/matkaDoublePatti/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "TRIPLE_PATTI":
          this.props.history.push(
            `${basePath}/matkaTriplePatti/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "SPMOTOR":
          this.props.history.push(
            `${basePath}/sp_Motor/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "DP_MOTOR":
          this.props.history.push(
            `${basePath}/DpMotor/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "KolkataSINGLE":
          this.props.history.push(
            `${basePath}/kolkataSingle/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        case "KolkataSinglePanna":
          this.props.history.push(
            `${basePath}/kolkataSinglePanna/${gameName}/${gameId}/${_id}/${elem}`,
          );
          break;
        default:
          console.warn("Unknown shortName:", data.shortName);
      }
    }
  };

  handleSidebar = (data) => {
    // console.log('datagameItemgameItemgameItem', data);
    this.setState({ gameItem: data });
  };
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake });
  };

  render() {
    let { users } = this.props;
    let { getMarketList, loading } = users;

    console.log("this.state.isOpen==true", this.state.isOpen == "true");

    return (
      // <div className='w-full h-full overflow-y-auto' style={{ backgroundImage: "url(/images/mw-ban.png)" }}>
      <div className="w-full h-full overflow-y-hidden relative bg-gray-200">
        <Loader loading={loading} />
        <div className=" bg-[#770E73] flex justify-center text-white items-end text-3xl h-16 rounded-b-3xl relative">
          <IoArrowBack
            className=" absolute cursor-pointer  left-3 "
            onClick={() => this.props.history.goBack()}
          />
          <div className="capitalize text-lg  ">
            {this.props.match.params.gameName}
          </div>
        </div>
        <div className="w-full py-5  h-[90vh] overflow-y-auto  flex flex-col gap-2 ">
          <div className="grid grid-cols-2 p-4 gap-4">
            {getMarketList &&
              Array.isArray(getMarketList) &&
              getMarketList.length > 0 &&
              getMarketList.map((e) => {
                // When isOpen is false, exclude specific markets by their shortName
                if (
                  this.state.isOpen == "false" &&
                  e?.shortName !== "JODIBULK" &&
                  e?.shortName !== "JODIFAMILY" &&
                  e?.shortName !== "JODI" &&
                  e?.shortName !== "REDJODI" &&
                  e?.shortName !== "FULLSANGAM" &&
                  e?.shortName !== "HALFSANGAM_B" &&
                  e?.shortName !== "HALFSANGAM_A"
                ) {
                  return (
                    <div key={e?.id} className="">
                      <img
                        onClick={() => this.navigateToGameSingle(e, false)}
                        src={e?.image}
                        alt="logo"
                        className="cursor-pointer w-[20rem] h-[13rem] rounded-3xl"
                      />
                    </div>
                  );
                }

                // When isOpen is true, show all markets
                if (this.state.isOpen == "true") {
                  return (
                    <div key={e?.id} className="">
                      <img
                        onClick={() => this.navigateToGameSingle(e, true)}
                        src={e?.image}
                        alt="logo"
                        className="cursor-pointer w-[20rem] h-[13rem] rounded-3xl"
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(SattaEvents);
