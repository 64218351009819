import React from 'react'

const SubmitTotalData = ({arrayofBets,totalAmount}) => {
    return (
        <div>
            <div className="grid grid-cols-2 items-center  border-gray-300   rounded-md  w-full">
                <div className=" ">
                    <p className="text-gray-600 "> Bids</p>
                    <p className="text-lg  text-gray-800 px-3">{arrayofBets }</p>
                </div>
                <div className=" ">
                    <p className="text-gray-600 "> Amount</p>
                    <p className="text-lg  text-gray-800 text-start px-2">{totalAmount}</p>
                </div>
            </div>
        </div>
    )
}

export default SubmitTotalData