export const CONST = {
  BACKEND_URL: 'https://paisawin222-backend.vercel.app/api/v1/'
  // BACKEND_URL: 'http://localhost:8804/api/v1/'
};

export const betChipsData = {
  "1000": 1000,
};

export const DomainName = 'live-game-24'
  ;
