import React from "react";
import { connect } from "react-redux";

import { FaRegCalendar } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { RxCountdownTimer } from "react-icons/rx";
import { betActions, userActions } from "../../../_actions";
import Loader from "../../../components/Loader/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { IoArrowBackOutline } from "react-icons/io5";
import { RiFilterFill } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";

class BidHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      isOpen: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
    };
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      // "keyWord": "",
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData = {
      // "keyWord": "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 200,
    };

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }

  getBatHistory = () => {
    this.setState({pageNo:1},()=>{

      let data = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      
      this.props.dispatch(betActions.betHistory(data));
    })
  };

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        // "keyWord": "",
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      this.props.dispatch(betActions.betHistory(pagination));
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet } = bet;
    let { totalGame, gameList } = users;
    const { isOpen } = this.state;
    const today = new Date().toISOString().split("T")[0];
    // const navigate = useNavigate();
    const handleNavigate = () => {
      this.props.history.push("/app/bidhistory");
    };

    // console.log("betList", betList);

    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen   ">
          <div className="flex justify-between place-items-center h-10 w-full border bg-[#770E73] rounded-b-3xl">
            <div className=" flex justify-start place-items-end px-4 gap-3">
              <IoArrowBackOutline
                className="text-white mb-1 text-lg"
                onClick={this.goBack}
              />
              <div className="  font-semibold text-white cursor-pointer">
                {" "}
                Bid History
              </div>
            </div>
            <div className="flex justify-start place-items-center text-white px-2 gap-2">
              <span className="font-semibold cursor-pointer ">Filter By</span>
              <span className="relative inline-block">
                <RiFilterFill
                  className="cursor-pointer"
                  onClick={this.toggleDropdown}
                />
                {isOpen && (
                  <div className="absolute right-0 mt-2 w-80 bg-white border text-black border-gray-300 rounded-lg shadow-lg z-[100]">
                    <div className="flex justify-end">
                      <IoCloseOutline
                        className="cursor-pointer"
                        onClick={this.toggleDropdown}
                      />
                    </div>
                    <div className="  border px-4">
                      <div className="">
                        <label className="">From Date</label>
                        <input
                          type="date"
                          max={today}
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker"
                          required
                          value={this.state.startDate}
                          onChange={this.inputChangeStartDate}
                        />
                      </div>
                      <div className="">
                        <label className="">To Date</label>
                        <input
                          type="date"
                          max={today}
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker"
                          required
                          value={this.state.endDate}
                          onChange={this.inputChangeEndDate}
                        />
                      </div>
                      <div className="">
                        <label className="">Game Name</label>
                        <select
                          onChange={this.handleChangeSelect}
                          name="sports_id"
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none block"
                        >
                          <option value="">All</option>
                          {gameList && gameList.length > 0
                            ? gameList.map((element, index) => (
                              <option
                                value={element && element._id}
                                className="capitalize "
                              >
                                {element && element.name}
                              </option>
                            ))
                            : null}
                        </select>
                      </div>
                      <div className="col-span-1 flex flex-col justify-end items-end p-4">
                        <label className=""> </label>
                        <button
                          onClick={() => this.getBatHistory()}
                          className="py-1.5 px-4 bg-[#3e44d5] text-white text-[0.8125rem] rounded"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>

          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className="p-4 overflow-hidden overflow-y-auto h-[90vh]">
            <div className="">
              <div className="flex flex-col gap-5 border h-full ">
                {betList &&
                  betList.length > 0 &&
                  betList.map((element, index) => {
                    return (
                      <div key={index} className="flex flex-col bg-white text-xs  border-gray-500 rounded-md border p-4 ">
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>S.No.</div> {this.state.offset + index + 1}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>Market</div> {element?.marketId?.name || "-"}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>Game</div> {element?.gameId?.name || "-"}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>NUMBER</div> {element?.betNumber || "-"}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>Point</div> {element?.amount || "-"}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>BID TYPE</div> {element?.isOpen ? "Open" : "Close"}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>Date</div>
                          {moment(new Date(parseInt(element.createdAt)))
                            .utcOffset("+05:30")
                            .format("DD-MM-YYYY hh:mm A")}
                        </div>
                        <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                          <div>Profit/Loss</div>
                          {element?.betStatus === 0 && <div className="text-yellow-500">Pending</div>}
                          {element?.betStatus === 1 && <div className="text-green-800">+{element?.winAmount}</div>}
                          {element?.betStatus === 2 && <div className="text-red-700">-{element?.lossAmount}</div>}
                        </div>
                      </div>
                    );
                  })}

                <nav className="flex justify-end mt-4 w-full">
                  {totalBet && totalBet > this.state.size ? (
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalBet / this.state.size}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-cls"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>

          <div className="">
            <div className=" mt-4  ">
              {/* <div className='  border grid grid-cols-1 lg:grid-cols-7 divide-x  lg:divide-y-0 '>
                            <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                                <label className=''>From Date</label>
                                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                                    name="Date"
                                    dateFormat="yyyy-mm-dd"
                                    id="datepicker" required
                                    value={this.state.startDate} onChange={this.inputChangeStartDate} />
                            </div>
                            <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                                <label className=''>To Date</label>
                                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                                    name="Date"
                                    dateFormat="yyyy-mm-dd"
                                    id="datepicker" required
                                    value={this.state.endDate} onChange={this.inputChangeEndDate}
                                />
                            </div>
                            <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                                <label className=''>Game Name</label>
                                <select onChange={this.handleChangeSelect} name="sports_id" className=' w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none block'>
                                    <option value="">All</option>
                                    {gameList && gameList.length > 0 ? gameList.map((element, index) => (
                                        <option value={element && element._id} className='capitalize '>{element && element.name}</option>
                                    )) : null}
                                </select>
                            </div>
                            <div className='col-span-1 flex flex-col justify-end items-end p-4'>
                                <label className=''> </label>
                                <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bg-[#3e44d5] text-white text-[0.8125rem] rounded'>Submit</button>
                            </div>
                        </div> */}
              <div className="">
                <div className="overflow-hidden overflow-y-auto">
                  {/* <div className='Bid_History  shadow-md bg-gray-100 rounded-md cursor-pointer' onClick={handleNavigate}>
                  <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                    <div className='flex justify-start place-items-center gap-4 px-4'>
                      <div className=''>
                        <span className="group-hover:text-[#f97941] bg-[#770E73] rounded-full flex justify-center place-items-center px-2 py-2"><FaRegCalendar className="text-xl  text-white" /></span>
                      </div>
                      <div className=''>
                        <p className='font-semibold'>Bid History</p>
                        <p className='text-gray-400 text-[12px]'>You can add fund to your wallet</p>
                      </div>

                    </div>
                    <div className='px-2'>
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                    </div>
                  </div>
                </div>
                <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2'>
                  <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                    <div className='flex justify-start place-items-center gap-4 px-4'>
                      <div className=''>
                        <span className="group-hover:text-[#f97941] bg-[#770E73] rounded-full flex justify-center place-items-center px-2 py-2"><RxCountdownTimer className="text-xl  text-white" /></span>
                      </div>
                      <div className=''>
                        <p className='font-semibold'>Game Result</p>
                        <p className='text-gray-400 text-[12px] '>You can View Your market result history </p>
                      </div>

                    </div>
                    <div className='px-2'>
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                    </div>
                  </div>
                </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2'>
                  <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                    <div className='flex justify-start place-items-center gap-4 px-4'>
                      <div className=''>
                        <span className="group-hover:text-[#f97941] bg-[#770E73] rounded-full flex justify-center place-items-center px-2 py-2"><FaRegCalendar className="text-xl  text-white" /></span>
                      </div>
                      <div className=''>
                        <p className='font-semibold'>Indian Starline Bid History</p>
                        <p className='text-gray-400 text-[12px]'>You can view starline history</p>
                      </div>

                    </div>
                    <div className='px-2'>
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                    </div>
                  </div>
                </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md '>
                  <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                    <div className='flex justify-start place-items-center gap-4 px-4'>
                      <div className=''>
                        <span className="group-hover:text-[#f97941] bg-[#770E73] rounded-full flex justify-center place-items-center px-2 py-2"><FaRegCalendar className="text-xl  text-white" /></span>
                      </div>
                      <div className=''>
                        <p className='font-semibold'>Indian Starline Result History</p>
                        <p className='text-gray-400 text-[12px]'>You can view starline result</p>
                      </div>

                    </div>
                    <div className='px-2'>
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                    </div>
                  </div>
                </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2 '>
                  <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                    <div className='flex justify-start place-items-center gap-4 px-4'>
                      <div className=''>
                        <span className="group-hover:text-[#f97941] bg-[#770E73] rounded-full flex justify-center place-items-center px-2 py-2"><FaRegCalendar className="text-xl  text-white" /></span>
                      </div>
                      <div className=''>
                        <p className='font-semibold'>indian Jackpot Bid History</p>
                        <p className='text-gray-400 text-[12px]'>You can view Jackpotackpot history</p>
                      </div>

                    </div>
                    <div className='px-2'>
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                    </div>
                  </div>
                </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2'>
                  <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                    <div className='flex justify-start place-items-center gap-4 px-4'>
                      <div className=''>
                        <span className="group-hover:text-[#f97941] bg-[#770E73] rounded-full flex justify-center place-items-center px-2 py-2"><FaRegCalendar className="text-xl  text-white" /></span>
                      </div>
                      <div className=''>
                        <p className='font-semibold'>indian Jackpot Result History</p>
                        <p className='text-gray-400 text-[12px]'>You can view Jackpotackpot result</p>
                      </div>

                    </div>
                    <div className='px-2'>
                      <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                    </div>
                  </div>
                </div> */}
                  {/* <div className="inline-block min-w-full "> */}
                  {/* <div className="overflow-hidden w-full "> */}

                  {/* <div className="  flex flex-col relative  h-[95vh]  gap-5 border   ">
                    {betList &&
                      betList.length > 0 &&
                      betList.map((element, index) => {
                        return (
                          <div className=" flex  w-full flex-col bg-white text-xs border-gray-500 rounded-md border p-4">
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>S.No.</div> {this.state.offset + index + 1}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>Market</div>{" "}
                              {element &&
                                element.marketId &&
                                element.marketId.name
                                ? element.marketId.name
                                : "-"}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>Game</div>
                              {element && element.gameId && element.gameId.name
                                ? element.gameId.name
                                : "-"}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>NUMBER</div>
                              {element && element.betNumber
                                ? element.betNumber
                                : "-"}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>Point</div>{" "}
                              {element && element.amount ? element.amount : "-"}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>BID TYPE</div>
                              {element && element.isOpen ? "Open" : "Close"}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>Date</div>
                              {moment(new Date(parseInt(element.createdAt)))
                                .utcOffset("+05:30")
                                .format("DD-MM-YYYY hh:mm A")}
                            </div>
                            <div className=" flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase">
                              <div>Profit/Loss</div>{" "}
                              {element && element.betStatus == 0 && (
                                <div className=" text-yellow-500">Pending</div>
                              )}
                              {element && element.betStatus == 1 && (
                                <div className=" text-green-800">
                                  +{element?.winAmount}
                                </div>
                              )}
                              {element && element.betStatus == 2 && (
                                <div className=" text-red-700">
                                  -{element?.lossAmount}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}

                    {
                      // <nav className=" rela z-0 flex mb-10 justify-end  w-76 pb-10 ">
                      //   {totalBet && totalBet > this.state.size ? (
                      //     <ReactPaginate
                      //       previousLabel={"Prev"}
                      //       nextLabel={"Next"}
                      //       breakLabel={"..."}
                      //       breakClassName={"break-me"}
                      //       pageCount={totalBet / this.state.size}
                      //       marginPagesDisplayed={1}
                      //       pageRangeDisplayed={1}
                      //       onPageChange={this.handlePageClick}
                      //       containerClassName={"pagination"}
                      //       pageClassName={"page-cls"}
                      //       activeClassName={"active"}
                      //     />
                      //   ) : null}
                      // </nav>
                    }
                  </div> */}

                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(BidHistory);
