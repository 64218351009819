import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'
import { jackportchartAction } from '../../_actions'
import moment from 'moment'
import Loader from '../../components/Loader/Loader'

function IndianJackpotResultHiastory() {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const [datedata, setdatedata] = useState("");
    const currentDate = new Date().toISOString().split('T')[0]; 

    useEffect(() => {
        let data = {
            filterDate: datedata ? datedata : "",
        };

        dispatch(jackportchartAction.calanderForJackpot(data))
    }, [datedata]);
    const handleOnChange = (e) => {
        setdatedata(e.target.value);

    }
    const goBack = () => {
        window.history.back()
    }

    let { jackpotchart } = selector ? selector : {};
    let { getjackpotChartList, loading } = jackpotchart ? jackpotchart : {};
    return (
        <>
            <Loader loading={loading} />
            <div className='bg-white text-black h-screen'>
                <div className='h-10 w-full border bg-[#770E73] rounded-b-3xl flex justify-start place-items-end px-4 gap-3'>
                    <IoArrowBackOutline className='text-white mb-1 text-lg' onClick={goBack} />
                    <div className='  font-semibold text-white cursor-pointer uppercase' > indian jackpot Result History</div>
                </div>
                <div className='flex justify-between place-items-center  p-3 text-sm'>
                    <p className='text-gray-600'>Select Date</p>
                    <div>
                        <input max={currentDate} type='date'
                            onChange={(e) => handleOnChange(e)}
                            className='bg-white px-3 py-1 uppercase rounded-full shadow' />
                    </div>
                </div>
                <div className="container mx-auto p-4 h-[99vh] overflow-auto">
                    {getjackpotChartList && getjackpotChartList.length > 0 && getjackpotChartList?.map((item, index) => (
                        <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs">
                            <div className="mb-2">
                                <strong>Date: </strong>
                                {moment(item.date).format('DD-MMM-YYYY')}

                            </div>
                            <div className="grid grid-cols-1">
                                {item.results.map((result, idx) => (
                                    <div key={idx} className="p-2 border flex justify-between rounded-md shadow-sm my-1">

                                        <div>
                                            <div>
                                                {moment(result.gameTime, 'HH:mm').format('hh:mm A')}
                                            </div>

                                        </div>
                                        <div>

                                            {result.jodiNumber}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )

}

export default IndianJackpotResultHiastory
