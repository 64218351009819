import React from "react";
import { connect } from "react-redux";
import { DomainName } from "../../_config/index";
import { alertActions, userActions } from "../../_actions";
import moment from "moment-timezone";
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPlayCircle } from "react-icons/fa";
import { IoCaretBackCircle } from "react-icons/io5";
import BetCloseAlert from "../../components/CloseBetAlert/CloseBetAlert";
import { IoCloseCircle } from "react-icons/io5";
// import { FaWhatsapp } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { IoArrowBack } from "react-icons/io5";
import { FaPause } from "react-icons/fa";
import { BsStopwatch } from "react-icons/bs";
import SorryMessage from "../../components/SorryModel/SorryMessage";
class BrandsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false,
      isOpenMessage:false
    };
  }

  componentDidMount() {
    // const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    // if (!hasSeenPopup) {
    //   this.setState({ notification: true });
    //   localStorage.setItem('hasSeenPopup', true);
    // }
    let data = {
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 100,
    };

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getAllbrandGame(data));
    this.props.dispatch(userActions.getProfile());
    // this.intervalId = setInterval(() => {
    // this.props.dispatch(userActions.getGamesList(data));

    // }, 2000);
  }

  // componentWillUnmount() {

  //   clearInterval(this.intervalId);
  // }

  navigateGameDetailsPage = (data , booleanValue) => {
    // console.log("booleanValue",booleanValue)
    if(!booleanValue){
      // alertActions.error("Closed for now")
      this.setState({isOpenMessage:true})
      return;
    }
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      this.props.history.push(`/app/matkaDetails2/${data.name}/${data._id}`);
    } else {
      // this.setState({ isPastClosingTime: true });
      this.props.history.push(`/app/matkaDetails2/${data.name}/${data._id}`);
    }
  };
  closemodel = ()=>{
    this.setState({isOpenMessage:false})
  }

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem("hasSeenPopup", true);
  };

  render() {
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let {
      totalGame,
      loading,
      gameList,
      brandData,
      currentTimeApi,
      appSettingData,
      slideData,
      indianStarlinegames,
    } = users;

    // const currentTimeApis = moment.utc(currentTimeApi * 1000).format("hh:mm A");
    const currentTimeApis = moment
      .utc(currentTimeApi * 1000)
      .tz("Asia/Kolkata")
      .format("hh:mm A");
    let recentList = [
      {
        name: "Sridevi",
        closingTime: 211331313131,
        openTime: 1131313131312,
      },
    ];

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    console.log("indianStarlinegames", indianStarlinegames);

    return (
      <>
        {/* <div className='w-full ' style={{ backgroundImage: "url(/images/bg-all.jpg)" }}> */}
        <div className="w-full bg-gray-100">
          <Loader loading={loading} />
          <div className=" flex space-x-1">
            <div className=" w-full relative  space-x-1">
              <div className=" bg-[#770E73] flex justify-center text-white items-end text-3xl h-16 rounded-b-3xl relative">
                <IoArrowBack
                  className=" absolute cursor-pointer  left-3 "
                  onClick={() => this.props.history.goBack()}
                />
                <div className="uppercase font-bold  text-base">
                  <span className="">indian Starline Dashboard</span>
                </div>
              </div>

              <div className="w-full space-y-1 h-[98vh] overflow-y-auto pb-20">
                <div className=" overflow-hidden  w-full">
                  {/* <marquee class="text-black font-bold mt-1  bg-[#f97941] text-sm py-2 bgheader">Welcome To  Profitplay.win - Your Ultimate Matka Experience! contactus  {appSettingData && appSettingData.length > 0 && appSettingData.filter((e) => e.key === "MobileNumber")[0]?.value} </marquee> */}
                  <div className="   justify-center grid grid-cols-2 gap-2 p-2  items-center mt-1">
                    <span className="bg-white shadow-md rounded-3xl px-2 py-1  font-semibold ">
                      Single Digit <span className=" text-[#C11C84]">1-100</span>
                    </span>
                    <span className="bg-white shadow-md rounded-3xl px-2 py-1  font-semibold ">
                      Double Pana <span className=" text-[#C11C84]">1-100</span>
                    </span>
                    <span className="bg-white shadow-md rounded-3xl px-2 py-1  font-semibold ">
                      Single Pana <span className=" text-[#C11C84]">1-100</span>
                    </span>
                    <span className="bg-white shadow-md rounded-3xl px-2 py-1  font-semibold ">
                      Triple Pana <span className=" text-[#C11C84]">1-100</span>
                    </span>
                  </div>

                  <div className="space-y-1  pt-2 px-4">
                    {indianStarlinegames && indianStarlinegames.length > 0
                      ? indianStarlinegames.map((element, index) => {
                          const closeCloseBidTime = moment
                            .duration(element?.betOpenTime, "seconds")
                            .asMinutes();

                          const openingTime = moment()
                            .hours(parseInt(element && element.openTime / 3600))
                            .minutes(
                              parseInt(
                                (element && element.openTime % 3600) / 60,
                              ),
                            )
                            .seconds(parseInt(element && element.openTime % 60))
                            .milliseconds(0);

                          const closingTime = moment()
                            .hours(
                              parseInt(element && element.closingTime / 3600),
                            )
                            .minutes(
                              parseInt(
                                (element && element.closingTime % 3600) / 60,
                              ),
                            )
                            .seconds(
                              parseInt(element && element.closingTime % 60),
                            )
                            .milliseconds(0);

                          const OpenBetTime = openingTime.format("hh:mm A");

                          const CloseBetTime = closingTime.format("hh:mm A");

                          const getCurrentTime = () => {
                            const now = moment();
                            return now.format("hh:mm:A");
                          };
                          const currentTimeApis = getCurrentTime();

                          const thirtyMinutesBeforeClosing = closingTime
                            .clone()
                            .subtract(closeCloseBidTime, "minutes");

                          const isBefore = moment(
                            currentTimeApis,
                            "hh:mm A",
                          ).isBefore(
                            moment(thirtyMinutesBeforeClosing, "hh:mm A"),
                          );

                          return (
                            // <div
                            //   onClick={() =>
                            //     this.navigateGameDetailsPage(element)
                            //   }
                            //   className="w-full border  rounded-3xl text-[#F97941] bg-[#3E44D5] "
                            // >
                            //   <div className=" flex justify-between items-center p-2">
                            //     <div className=" flex flex-col justify-center rounded-full  items-start">
                            //       <img
                            //         src={element?.image}
                            //         className="md:h-24 md:w-24 h-20 w-20 rounded-full"
                            //       />
                            //     </div>
                            //     <div className=" flex justify-center items-center  flex-col">
                            //       <div className="md:text-3xl text-2xl mt-2 font-bold">
                            //         {(OpenBetTime && OpenBetTime) || ""}
                            //       </div>

                            //       <h2 className="text-lg font-semibold">
                            //         {element &&
                            //         element?.market &&
                            //         element?.market?.openNumber.toString() ==
                            //           "0"
                            //           ? "000"
                            //           : element?.market?.openNumber.toString() ||
                            //             "***"}
                            //         -
                            //         {(element &&
                            //           element?.market &&
                            //           element?.market?.jodiNumber &&
                            //           element?.market?.jodiNumber.split(
                            //             "",
                            //           )[0]) ||
                            //           "*"}
                            //       </h2>
                            //       {isBefore &&
                            //       element?.onOf &&
                            //       element?.market?.openNumber == null ? (
                            //         <div className="w-full text-[#38EF7D]  uppercase font-bold text-center">
                            //           Betting Running
                            //         </div>
                            //       ) : (
                            //         <div className="w-full text-[#FE0000] font-bold  uppercase text-center">
                            //           Betting Closed
                            //         </div>
                            //       )}
                            //     </div>

                            //     <div className=" flex  justify-center gap-3 items-center">
                            //       <div
                            //         className={`flex justify-center rounded-full  text-white ${
                            //           isBefore &&
                            //           element?.onOf &&
                            //           element?.market?.openNumber == null
                            //             ? "bg-[#198f51]"
                            //             : "bg-[#F97941]"
                            //         } w-12 h-12  items-center`}
                            //       >
                            //         {isBefore &&
                            //         element?.onOf &&
                            //         element?.market?.openNumber == null ? (
                            //           <FaPlay className=" text-xl cursor-pointer" />
                            //         ) : (
                            //           <FaPause className=" text-xl " />
                            //         )}
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>
                            <div
                              onClick={() =>
                                this.navigateGameDetailsPage(
                                  element,
                                  isBefore &&
                                    element?.market?.openNumber == null,
                                )
                              }
                              className=" flex justify-between bg-white py-3 px-1   overflow-x-hidden items-center"
                            >
                              <div className="flex w-40 md:w-44  justify-start gap-2 items-center">
                                <div>
                                  {isBefore &&
                                  element?.market?.openNumber == null ? (
                                    <BsStopwatch className=" text-[#C11C84] text-5xl" />
                                  ) : (
                                    <BsStopwatch className=" text-gray-400 text-5xl" />
                                  )}
                                </div>
                                <div>
                                  <div>
                                    {" "}
                                    {(OpenBetTime && OpenBetTime) || ""}
                                  </div>
                                  {isBefore &&
                                  element?.market?.openNumber == null ? (
                                    <div className=" md:text-sm text-xs text-green-800">
                                      Running Now
                                    </div>
                                  ) : (
                                    <div className=" md:text-sm text-xs text-red-700">
                                      Close for today
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="bg-[#770E73] text-white px-4 py-1 w-24  text-center flex justify-center items-center rounded-3xl">
                                       {element &&
                                    element?.market &&
                                    element?.market?.openNumber.toString() ==
                                      "0"
                                      ? "000"
                                      : element?.market?.openNumber.toString() ||
                                        "***"}
                                    
                                    - {(element &&
                                      element?.market &&
                                      element?.market?.jodiNumber &&
                                      element?.market?.jodiNumber.split(
                                        "",
                                      )[0]) ||
                                      "*"}

                              </div>

                              <div>
                                {isBefore &&
                                element?.market?.openNumber == null ? (
                                  <FaPlayCircle className="text-[#C11C84] text-4xl" />
                                ) : (
                                  <IoCloseCircle className="text-gray-400 text-4xl" />
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <SorryMessage 
            isOpenMessage={this.state.isOpenMessage}
            closemodel={this.closemodel}
            />
          </div>
          {this.state.isPastClosingTime ? (
            <BetCloseAlert handleCloseAlert={this.handleCloseAlert} />
          ) : null}

          {notification && (
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />
          )}
        </div>
        {/* <Footer props={this.props} /> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(BrandsList);
