import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';
const Rate = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { loading, rateList
  } = users;

  useEffect(() => {
    dispatch(userActions.getMarketRate());
  }, [])

  return (
    <>
      <Loader loading={loading} />
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex justify-between place-items-center h-10 w-full border bg-[#770E73] rounded-b-3xl'>
          <div className=" flex justify-start place-items-end px-4 gap-3">
            <IoArrowBackOutline
              className="text-white mb-1 text-lg"
              onClick={() => history.push('/')}
            />
            <div className="  font-semibold text-white cursor-pointer">
              {" "}
              Game Rate
            </div>
          </div>
        </div>

        <div className=' space-x-1 p-1 h-[90vh] overflow-hidden overflow-y-auto mt-4'>
          <div className='game-win ratio'>
            <div className='text-center bg-[#770E73] text-white py-1 rounded-md mx-4'>Game Rate </div>
          </div>
          <div className='w-full'>



            
            <div className='  p-5 flex flex-col gap-4 rounded-md mb-10 '>
              {rateList && rateList.length > 0 && rateList.filter(e=>(!e.isJackpot&&!e.isStarLine)).map((e) => {

                return (

                  <div onClick={() => history.push(`/app/charts/${e?._id}/${e?.name}`)} title='chart' className=' capitalize cursor-pointer bg-gray-50 shadow-md text-black flex justify-center items-center px-3 py-2 rounded-lg '>
                    <div>{e?.name} :</div>
                    <div className='px-2'>10 Ka : {e?.point * 10}</div>
                  </div>
                )

              })}

            </div>
          </div>



          <div className='game-win ratio'>
            <div className='text-center bg-[#770E73] text-white py-1 rounded-md mx-4'>Game Rate of Starline</div>
          </div>
          <div className='w-full'>
            <div className='  p-5 flex flex-col gap-4 rounded-md mb-10 '>
              {rateList && rateList.length > 0 && rateList.filter(e=>(!e.isJackpot&&e.isStarLine)).map((e) => {

                return (

                  <div onClick={() => history.push(`/app/charts/${e?._id}/${e?.name}`)} title='chart' className=' capitalize cursor-pointer bg-gray-50 shadow-md text-black flex justify-center items-center px-3 py-2 rounded-lg '>
                    <div>{e?.name} :</div>
                    <div className='px-2'>10 Ka : {e?.point * 10}</div>
                  </div>
                )

              })}

            </div>
          </div>



          <div className='game-win ratio'>
            <div className='text-center bg-[#770E73] text-white py-1 rounded-md mx-4'>Game Rate of JackPot</div>
          </div>
          <div className='w-full'>
            <div className='  p-5 flex flex-col gap-4 rounded-md mb-10 '>
              {rateList && rateList.length > 0 && rateList.filter(e=>(e.isJackpot&&!e.isStarLine)).map((e) => {

                return (

                  <div onClick={() => history.push(`/app/charts/${e?._id}/${e?.name}`)} title='chart' className=' capitalize cursor-pointer bg-gray-50 shadow-md text-black flex justify-center items-center px-3 py-2 rounded-lg '>
                    <div>{e?.name} :</div>
                    <div className='px-2'>10 Ka : {e?.point * 10}</div>
                  </div>
                )

              })}

            </div>
          </div>


        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(Rate));